(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

//Gets the token working correctly in jQuery's $.ajax function
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

//Mask all the telephone inputs
$('input[type=tel]').mask('(999) 999-9999? x99999');
$('input[data-mask]').each(function () {
    $(this).mask('9999-99999');
});

//This is because number inputs fail, so they're all text inputs with this class
$('.number-input').on('input', function () {
    this.value = this.value.replace(/\D/g, '');
});

//Session timeout warning after 80 minutes of inactivity
(function () {
    var timeout = 80 * 60 * 1000,
        timer = setTimeout(function () {
        alert('Your session will expire in ten minutes due to inactivity.');
    }, timeout);
})();

//Flips a table from horizontal to vertical
Element.prototype.flipVertical = function () {
    var tBody = document.createElement('tbody'),
        newRow,
        temp,
        i,
        j,
        headers,
        rows,
        cells;
    if (this.tagName !== 'TABLE') {
        console.log('Element is not a table.');return;
    }
    headers = this.querySelectorAll('thead tr th');
    if (headers.length === 0) {
        console.log('Invalid table format.');return;
    }
    rows = this.querySelectorAll('tbody tr');
    for (i = 0; i < headers.length; i += 1) {
        newRow = document.createElement('tr');
        temp = document.createElement('th');
        temp.innerHTML = headers[i].innerHTML;
        newRow.appendChild(temp);
        for (j = 0; j < rows.length; j += 1) {
            cells = rows[j].querySelectorAll('td');
            temp = document.createElement('td');
            temp.innerHTML = cells[i].innerHTML;
            newRow.appendChild(temp);
        }
        tBody.appendChild(newRow);
    }
    this.innerHTML = '';
    this.appendChild(tBody);
};

},{}]},{},[1]);
